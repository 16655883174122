@use "../config" as *;
%btn-link-common {
    @extend %btn-default;
    @extend %gap-2;
    @extend %flex;
    &::after {
        @include icon(arrow-right, 19);
    }
}
@each $federation, $federation-img in $federation-bg {
    .#{$federation} {
        .waf-federationdetail {
            .waf-head {
                background: url("/static-assets/images/cssimages/world-ranking/#{$federation-img}.png?v=#{$image-version}") bottom center/cover no-repeat;
            }
        }
    }
}
.waf-federationdetail {
    padding-block: 0 var(--space-14);
    margin-bottom: 0;
    @extend %page-head-blue-bg;
    .head-wrap {
        margin-block: 21.6rem;
    }
    .waf-body {
        @extend %bg-transparent;
    }
    .content {
        &-title {
            @extend %title-40;
            @extend %neutral-100;
            @extend %mb-0;
        }
        &-section-wrapper {
            @extend %flex-column;
            @extend %gap-6;
        }
        &-thumbnail {
            @extend %flex-column-n-c;
            @extend %gap-4;
            @extend %my-6;
            .image {
                width: 16rem;
                height: 16rem;
                object-fit: contain;
            }
            .country {
                @extend %flex-n-c;
                @extend %gap-2;
                .image {
                    width: 2.2rem;
                    height: 2.2rem;
                }
            }
            .text {
                @extend %body-l;
                @extend %neutral-70;
                @extend %capitalize;
            }
        }
        &-event-wrapper {
            @extend %tabs-underline;
            @extend %event-item;
            .meta {
                @extend %body-l;
            }
            .card-left {
                @extend %pl-2;
            }
            .card-title {
                font-size: 2rem;
                height: 2.5rem;
            }
            .card-item {
                border-block: 0;
                padding-inline: 0;
                grid-template-columns: auto 22rem;
                &:not(:last-child) {
                    @include border(1, neutral-20, 10, bottom);
                }
            }
            .tabs {
                @extend %mb-4;
                @include border(1, neutral-20, 10, bottom);
            }
            .footer {
                @extend %mt-4;
                @extend %flex-c-c;
            }
            .btn-link {
                @extend %btn-link-common;
            }
        }
        &-listing-wrapper {
            @include article-listing(horizontal);
            .img-box {
                aspect-ratio: 1/1;
            }
            .article-list {
                display: grid;
            }
        }
        &-ranking-wrapper {
            @extend %accordion-common;
            .table {
                .pos {
                    flex-basis: 4rem;
                }
                .name {
                    flex-basis: 15rem;
                }
                .country {
                    flex-basis: 8rem;
                    @extend %flex-n-c;
                    @extend %gap-2;
                    .image {
                        width: 2.4rem;
                        height: 2.4rem;
                        @extend %circle-radius;
                    }
                    .text {
                        @extend %uppercase;
                        @extend %neutral-100;
                        @extend %body-l;
                    }
                }
                .time {
                    flex-basis: 5rem;
                }
                &-row {
                    overflow-x: auto;
                    justify-content: flex-start;
                    @extend %py-3;
                }
                &-data {
                    flex-shrink: 0;
                    justify-content: flex-start;
                    @extend %px-2;
                    .text {
                        @extend %capitalize;
                        @extend %neutral-100;
                        @extend %body-l;
                    }
                }
                &-footer {
                    @extend %mt-4;
                    @extend %flex-c-c;
                    .btn-link {
                        @extend %btn-default;
                    }
                }
            }
        }
    }
    .content-detail {
        &-wrapper {
            @extend %flex-column;
            @extend %gap-6;
            > * {
                @extend %p-6-4;
                @extend %border-radius-m;
                @extend %bg-neutral-0;
            }
            .head {
                @extend %flex-sb-c;
                @extend %mb-9;
                .btn-link {
                    @extend %px-0;
                    @extend %py-3;
                    @extend %btn-link-common;
                }
            }
        }
        &-about {
            @extend %p-6-4;
            @extend %border-radius-m;
            @extend %bg-neutral-0;
            .label {
                width: 10rem;
                @extend %capitalize;
                @extend %neutral-100;
                @extend %body-lb;
            }
            .data {
                @extend %text-right;
                @extend %capitalize;
                @extend %neutral-70;
                @extend %body-l;
                @extend %flex-column;
                .btn-link {
                    @extend %btn-default;
                    padding-inline: 0;
                    min-height: unset;
                    text-transform: none;
                    width: 20rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @extend %hidden;
                    @extend %d-block;
                    @extend %text-right;
                }
            }
            .label-item {
                padding-block: var(--space-3) var(--space-2);
                @extend %flex-sb-c;
                @extend %px-2;
                @include border(1, neutral-20, 10, top);
            }
        }
        &-card-wrapper {
            order: 3;
            @extend %flex-column;
            @extend %gap-6;
        }
        &-about,
        &-card {
            @extend %p-6-4;
            @extend %border-radius-m;
            @extend %bg-neutral-0;
        }
        &-card {
            .card-list {
                @extend %mt-4;
                @extend %event-card-small;
                .title {
                    font-size: 1.6rem;
                }
                .card-item {
                    min-height: unset;
                    &::before {
                        opacity: 1;
                    }
                }
                .btn-link {
                    &::before {
                        content: '\e84d';
                    }
                }
            }
            &.others {
                order: 4;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-federationdetail {
        .content {
            &-event-wrapper {
                .card-item {
                    grid-template-columns: auto 64rem;
                }
            }
            &-listing-wrapper {
                .article-thumbnail {
                    width: 30.2rem;
                }
                .img-box {
                    aspect-ratio: 16/9;
                }
            }
            &-ranking-wrapper {
                .table-responsive {
                    overflow: hidden;
                }
                .table {
                    .pos {
                        flex-basis: 4rem;
                    }
                    .name {
                        flex-basis: calc(100% - 28rem);
                    }
                    .country {
                        flex-basis: 15rem;
                    }
                    .time {
                        flex-basis: 9rem;
                    }
                }
            }
        }
        .content-detail {
            &-about {
                .label {
                    width: 15rem;
                }
            }
            &-card {
                .card-list {
                    gap: var(--space-4);
                    @include flex-config(flex, column, null, null);
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-federationdetail {
        .content {
            &-section-wrapper {
                display: grid;
                grid-template-columns: 45rem calc(100% - 47.4rem);
                > * {
                    &:not(:last-child) {
                        padding: var(--space-6);
                    }
                }
            }
            &-thumbnail {
                .image {
                    width: 21.8rem;
                    height: 21.8rem;
                }
            }
        }
        .content-detail {
            &-wrapper {
                grid-area: 1/2/6/3;
                > * {
                    padding: var(--space-12);
                }
            }
            &-about {
                height: max-content;
                grid-area: 1 / 1 / 2 / 2;
            }
        }
    }
}